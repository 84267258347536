export enum ACL {
  WITHOUT_PRIVILEGE = '',
  ADJUDICACION_LISTAR_ADJUDICACION = 'ADJUDICACION_LISTAR_ADJUDICACION',
  ADMIN = 'ADMIN',
  ASUNTO_LLAMADO_LISTAR = 'ASUNTO_LLAMADO_LISTAR',
  ASUNTO_LLAMADO_MODIFICAR = 'ASUNTO_LLAMADO_MODIFICAR',
  AUDITORIA_CARGAR = 'AUDITORIA_CARGAR',
  AUDITORIA_LISTAR = 'AUDITORIA_LISTAR',
  AUDITORIA_MODIFICAR = 'AUDITORIA_MODIFICAR',
  BAJA_LISTAR__PENDIENTE_BAJA_PROCESAR = 'BAJA_LISTAR__PENDIENTE_BAJA_PROCESAR',
  BAJA_LISTAR__PENDIENTE_BAJA_RESTAURAR = 'BAJA_LISTAR__PENDIENTE_BAJA_RESTAURAR',
  BAJA_LISTAR_PENDIENTE_BAJA = 'BAJA_LISTAR_PENDIENTE_BAJA',
  BAJA_LISTAR_TITULO_ADEUDADO = 'BAJA_LISTAR_TITULO_ADEUDADO',
  BAJA_LISTAR_TITULO_ADEUDADO_CARGAR = 'BAJA_LISTAR_TITULO_ADEUDADO_CARGAR',
  BAJAS_POR_PROCESAR_LISTAR_BAJA_DADA = 'BAJAS_POR_PROCESAR_LISTAR_BAJA_DADA',
  INTERESADO_ASIGNADO_LISTAR = 'INTERESADO_ASIGNADO_LISTAR',
  INTERESADO_ASIGNADO_CARGAR = 'INTERESADO_ASIGNADO_CARGAR',
  CLIENTE_BUSCAR = 'CLIENTE_BUSCAR',
  CLIENTE_CARGAR = 'CLIENTE_CARGAR',
  CLIENTE_MODIFICAR = 'CLIENTE_MODIFICAR',
  COBRANZA_LISTAR = 'COBRANZA_LISTAR',
  COMPRADO_CARGAR = 'COMPRADO_CARGAR',
  COMPRADO_LISTAR = 'COMPRADO_LISTAR',
  DEBITO_AUTOMATICO_CARGAR = 'DEBITO_AUTOMATICO_CARGAR',
  DEBITO_AUTOMATICO_LISTAR = 'DEBITO_AUTOMATICO_LISTAR',
  DEUDOR_LISTAR_ATRASADO = 'DEUDOR_LISTAR_ATRASADO',
  DEUDOR_LISTAR_CAIDO = 'DEUDOR_LISTAR_CAIDO',
  DEUDOR_LISTAR_LEGAL = 'DEUDOR_LISTAR_LEGAL',
  DEUDOR_LISTAR_MOROSO = 'DEUDOR_LISTAR_MOROSO',
  DEUDOR_LISTAR_OPERACION = 'DEUDOR_LISTAR_OPERACION',
  EMITIDO_LISTAR_EMITIDO = 'EMITIDO_LISTAR_EMITIDO',
  ENCOMIENDA_CARGAR = 'ENCOMIENDA_CARGAR',
  ENCOMIENDA_LISTAR = 'ENCOMIENDA_LISTAR',
  ENCOMIENDA_MODIFICAR = 'ENCOMIENDA_MODIFICAR',
  IMPRESION_BUSCAR = 'IMPRESION_BUSCAR',
  INGRESO_LISTAR_INGRESO = 'INGRESO_LISTAR_INGRESO',
  INGRESO_LISTAR_INGRESO_ADJUDICADO_POR_LICITACION = 'INGRESO_LISTAR_INGRESO_ADJUDICADO_POR_LICITACION',
  INGRESO_LISTAR_INGRESO_FUERA_DE_TERMINO = 'INGRESO_LISTAR_INGRESO_FUERA_DE_TERMINO',
  INTERESADO_CARGAR = 'INTERESADO_CARGAR',
  INTERESADO_LISTAR = 'INTERESADO_LISTAR',
  INTERESADO_MODIFICAR = 'INTERESADO_MODIFICAR',
  INTERESADO_CLIENTE_CARGAR = 'INTERESADO_CLIENTE_CARGAR',
  INTERESADO_CLIENTE_LISTAR = 'INTERESADO__CLIENTE_LISTAR',
  INTERESADO_CLIENTE_MODIFICAR = 'INTERESADO__CLIENTE_MODIFICAR',
  LLAMADO_ASIGNADO_LISTAR = 'LLAMADO_ASIGNADO_LISTAR',
  LLAMADO_ASIGNADO_LISTAR_ASIGNADOS = 'LLAMADO_ASIGNADO_LISTAR_ASIGNADOS',
  LLAMADO_ASIGNADO_LISTAR_PROMESA = 'LLAMADO_ASIGNADO_LISTAR_PROMESA',
  LLAMADO_CARGAR = 'LLAMADO_CARGAR',
  LLAMADO_LISTAR = 'LLAMADO_LISTAR',
  LLAMADO_RESPUESTA = 'LLAMADO_RESPUESTA',
  NOTIFICACION = 'NOTIFICACION',
  OBLEA_CARGAR = 'OBLEA_CARGAR',
  OBLEA_LISTAR = 'OBLEA_LISTAR',
  OBLEA_MODIFICAR = 'OBLEA_MODIFICAR',
  OPERACION_CARGAR = 'OPERACION_CARGAR',
  OPERACION_LISTAR = 'OPERACION_LISTAR',
  PAGOS_COMPROBANTES = 'PAGOS_COMPROBANTES',
  PANEL_TITULO_PRODUCCION_MENSUAL = 'PANEL_TITULO_PRODUCCION_MENSUAL',
  PERMISO_BORRAR_ROL = 'PERMISO_BORRAR_ROL',
  PERMISO_CARGAR_ROL = 'PERMISO_CARGAR_ROL',
  PERMISO_LISTAR_ROL = 'PERMISO_LISTAR_ROL',
  PERMISO_LISTAR_USUARIO = 'PERMISO_LISTAR_USUARIO',
  PERMISO_MODIFICAR_ROL = 'PERMISO_MODIFICAR_ROL',
  PERMISO_MODIFICAR_USUARIO = 'PERMISO_MODIFICAR_USUARIO',
  PLAN_BORRAR = 'PLAN_BORRAR',
  PLAN_CARGAR = 'PLAN_CARGAR',
  PLAN_LISTAR = 'PLAN_LISTAR',
  PRODUCCION_MENSUAL_LISTAR = 'PRODUCCION_MENSUAL_LISTAR',
  SORTEO_CARGAR = 'SORTEO_CARGAR',
  SORTEO_LISTAR = 'SORTEO_LISTAR',
  SUSCRIPCION_CARGAR = 'SUSCRIPCION_CARGAR',
  SUSCRIPCION_LISTAR = 'SUSCRIPCION_LISTAR',
  SUSCRIPCION_MARCAR_RECIBIDAS = 'SUSCRIPCION_MARCAR_RECIBIDAS',
  SUSCRIPCION_RESTAURAR = 'SUSCRIPCION_RESTAURAR',
  TITULO_BUSCAR = 'TITULO_BUSCAR',
  TITULO_CARGAR = 'TITULO_CARGAR',
  TITULO_LISTAR = 'TITULO_LISTAR',
  TITULO_ENDOSO = 'TITULO_ENDOSO',
  TITULO_MODIFICAR = 'TITULO_MODIFICAR',
  TITULOS_LISTAR_EXPORTAR_ACTIVOS = 'TITULOS_LISTAR_EXPORTAR_ACTIVOS',
  USUARIO_CARGAR = 'USUARIO_CARGAR',
  USUARIO_LISTAR = 'USUARIO_LISTAR',
  USUARIO_LOGIN = 'USUARIO_LOGIN',
  USUARIO_MODIFICAR = 'USUARIO_MODIFICAR',
  VACACIONES_CARGAR = 'VACACIONES_CARGAR',
  VACACIONES_LISTAR = 'VACACIONES_LISTAR',
  VIGENTE_LISTAR_VIGENTE = 'VIGENTE_LISTAR_VIGENTE',
  VIGENTE_LISTAR_VIGENTE_SORTEO = 'VIGENTE_LISTAR_VIGENTE_SORTEO',
  VIGENTE_LISTAR_VIGENTE_VALOR_NOMINAL = 'VIGENTE_LISTAR_VIGENTE_VALOR_NOMINAL',
  WEB_PODERSA = 'WEB_PODERSA',
  TITULO_BUSCAR_BOTON_OBLEA_LISTAR = 'TITULO_BUSCAR_BOTON_OBLEA_LISTAR',
  LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_FILTRO_USUARIO = 'LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_FILTRO_USUARIO',
  LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_BOTON_REASIGNAR = 'LLAMADO_ASIGNADO_LISTAR_ASIGNADOS_BOTON_REASIGNAR',
  LLAMADOS_ASIGNADOS_EXPORTAR = 'LLAMADOS_ASIGNADOS_EXPORTAR',
  UIF_LISTAR = 'UIF_LISTAR',
  FONDO_AHORRO_OBTENER_DATOS = 'FONDO_AHORRO_OBTENER_DATOS',
  PAGO_BUSCAR = 'PAGO_BUSCAR',
  FINALIZADOS_LISTAR_ACTIVOS = 'FINALIZADOS_LISTAR_ACTIVOS',
  TITULOS_LISTAR_CUOTAS_COMPLETAS = 'TITULOS_LISTAR_CUOTAS_COMPLETAS',
  RESCATES_LISTAR = 'RESCATES_LISTAR',
  RESCATES_ELIMINAR = 'RESCATES_ELIMINAR',
  RESCATES_CARGAR = 'RESCATES_CARGAR',
  LICITACION_LISTAR = 'LICITACION_LISTAR',
  LICITACION_FAVORECER = 'LICITACION_FAVORECER',
  LICITACIONES_ANULAR = 'LICITACIONES_ANULAR',
  LICITACIONES_LISTAR_DEBEN_CUOTAS = 'LICITACIONES_LISTAR_DEBEN_CUOTAS',
  LICITACIONES_LISTAR_TITULOS = 'LICITACIONES_LISTAR_TITULOS',
  TITULO_LISTAR_ENVIAR_EMAIL = 'TITULO_LISTAR_ENVIAR_EMAIL',
  IMPRESION_BUSCAR_ENVIAR = 'IMPRESION_BUSCAR_ENVIAR ',
  TITULO_LISTAR_DESCARGAR_ARCHIVO = 'TITULO_LISTAR_DESCARGAR_ARCHIVO',
  PAGOS_DESCARGAR_ARCHIVOS_LINK = 'PAGOS_DESCARGAR_ARCHIVOS_LINK',
  TITULOS_FALTA_PAGO_LISTAR = 'TITULOS_FALTA_PAGO_LISTAR',
  PAGO_CARGAR = 'PAGO_CARGAR',
  PAGO_ELIMINAR = 'PAGO_ELIMINAR',
  PAGO_TERMINAR_PAGO = 'PAGO_TERMINAR_PAGO',
  SUSCRIPCIONES_CLIENTES_MODIFICAR_AVANZADO = 'SUSCRIPCIONES_CLIENTES_MODIFICAR_AVANZADO',
  BAJA_CARGAR_TITULO = 'BAJA_CARGAR_TITULO',
  LICITACION_CARGAR = 'LICITACION_CARGAR',
  TITULOS_A_RESCATAR_LISTAR = 'TITULOS_A_RESCATAR_LISTAR',
  VENTA_DATERO_LLAMADO_EXPORTAR = 'VENTA_DATERO_LLAMADO_EXPORTAR',
  ARREGLOS_MENSUALES_LISTAR = 'ARREGLOS_MENSUALES_LISTAR',
  ARREGLOS_MENSUALES_CARGAR = 'ARREGLOS_MENSUALES_CARGAR',
  ARREGLOS_MENSUALES_LIQUIDACIONES_LISTAR = 'ARREGLOS_MENSUALES_LIQUIDACIONES_LISTAR',
  ARREGLOS_MENSUALES_LIQUIDACIONES_CARGAR = 'ARREGLOS_MENSUALES_LIQUIDACIONES_CARGAR',
  ARREGLOS_MENSUALES_PENDIENTES_PAGO_LISTAR = 'ARREGLOS_MENSUALES_PENDIENTES_PAGO_LISTAR',
  ARREGLOS_MENSUALES_PENDIENTES_LIQUIDACION_LISTAR = 'ARREGLOS_MENSUALES_PENDIENTES_LIQUIDACION_LISTAR',
  ARREGLOS_MENSUALES_MARCAR_PARA_PAGAR = 'ARREGLOS_MENSUALES_MARCAR_PARA_PAGAR',
  ARREGLOS_MENSUALES_MODIFICAR_PARA_LIQUIDAR = 'ARREGLOS_MENSUALES_MODIFICAR_PARA_LIQUIDAR',
  ARREGLOS_MENSUALES_MARCAR_PAGO_CARGADO = 'ARREGLOS_MENSUALES_MARCAR_PAGO_CARGADO',
  ARREGLOS_MENSUALES_BORRAR = 'ARREGLOS_MENSUALES_BORRAR',
  EN_LEGAL_LISTAR = 'EN_LEGAL_LISTAR',
  EN_LEGAL_CARGAR = 'EN_LEGAL_CARGAR',
  EN_LEGAL_BUSCAR_ID = 'EN_LEGAL_BUSCAR_ID',
  EN_LEGAL_MODIFICAR = 'EN_LEGAL_MODIFICAR',
  EN_LEGAL_BORRAR = 'EN_LEGAL_BORRAR',
  EN_LEGAL_PREVISIONES_LISTAR = 'EN_LEGAL_PREVISIONES_LISTAR',
  EN_LEGAL_PREVISIONES_CARGAR = 'EN_LEGAL_PREVISIONES_CARGAR',
  EN_LEGAL_PREVISIONES_MODIFICAR = 'EN_LEGAL_PREVISIONES_MODIFICAR',
  EN_LEGAL_PREVISIONES_EXPORTAR = 'EN_LEGAL_PREVISIONES_EXPORTAR',
  EN_LEGAL_COMENTARIO_LISTAR = 'EN_LEGAL_COMENTARIO_LISTAR',
  EN_LEGAL_COMENTARIO_CARGAR = 'EN_LEGAL_COMENTARIO_CARGAR',
  AGENCIAS_LISTAR = 'AGENCIAS_LISTAR',
  AGENCIAS_CARGAR = 'AGENCIAS_CARGAR',
  AGENCIAS_MODIFICAR = 'AGENCIAS_MODIFICAR',
  AGENCIAS_DAR_BAJA = 'AGENCIAS_DAR_BAJA',
  AGENCIAS_RESTAURAR = 'AGENCIAS_RESTAURAR',
  CONTABLE_RECAUDACION_LISTAR = 'RECAUDACION_LISTAR',
  RECAUDACION_CARGAR = 'RECAUDACION_CARGAR',
  RECAUDACION_MODIFICAR = 'RECAUDACION_MODIFICAR',
  RECAUDACION_BUSCAR_ID = 'RECAUDACION_BUSCAR_ID',
  AGENTES_LISTAR = 'AGENTES_LISTAR',
  AGENTES_CARGAR = 'AGENTES_CARGAR',
  AGENTES_MODIFICAR = 'AGENTES_MODIFICAR',
  AGENTES_ELIMINAR = 'AGENTES_ELIMINAR',
  VENTA_DATERO_MARCAR_COMO_LIQUIDADA = 'VENTA_DATERO_MARCAR_COMO_LIQUIDADA',
  IGJ_VISTAS_MARCAR_PRESENTADA = 'IGJ_VISTAS_MARCAR_PRESENTADA',
  IGJ_VISTAS_BORRAR = 'IGJ_VISTAS_BORRAR',
  CONTACTOS_LISTAR = 'CONTACTOS_LISTAR',
  CONTACTOS_CARGAR = 'CONTACTOS_CARGAR',
  IGJ_CADUCOS_LISTAR = 'IGJ_CADUCOS_LISTAR',
  IGJ_VISTAS_CARGAR = 'IGJ_VISTAS_CARGAR',
  IGJ_VISTAS_MODIFICAR = 'IGJ_VISTAS_MODIFICAR',
  IGJ_VISTA_ARCHIVOS_LISTAR = 'IGJ_VISTA_ARCHIVOS_LISTAR',
  IGJ_VISTAS_COMENTARIOS_CARGAR = 'IGJ_VISTAS_COMENTARIOS_CARGAR',
  IGJ_RESCINDIDOS_LISTAR = 'IGJ_RESCINDIDOS_LISTAR',
  IGJ_RESERVAS_MATEMATICAS = 'IGJ_RESERVAS_MATEMATICAS',
  AGENTES_INTERESADOS_LISTAR = 'AGENTES_INTERESADOS_LISTAR',
  AGENTES_INTERESADOS_CARGAR = 'AGENTES_INTERESADOS_CARGAR',
  AGENTES_INTERESADOS_MODIFICAR = 'AGENTES_INTERESADOS_MODIFICAR',
  ENCUESTA_LISTAR = 'ENCUESTA_LISTAR',
  AGENCIAS_LISTAR_ESTADISTICAS = 'AGENCIAS_LISTAR_ESTADISTICAS',
  TAREAS_LISTAR = 'TAREAS_LISTAR',
  TAREAS_FINALIZAR = 'TAREAS_FINALIZAR',
  TAREAS_VOLVER_A_PENDIENTE = 'TAREAS_VOLVER_A_PENDIENTE',
  TAREAS_CARGAR = 'TAREAS_CARGAR',
  TAREAS_MODIFICAR = 'TAREAS_MODIFICAR',
  TAREAS_MODIFICAR_ESTADO = 'TAREAS_MODIFICAR_ESTADO',
  COMISIONES_LISTAR = 'COMISIONES_LISTAR',
  COMISIONES_CARGAR = 'COMISIONES_CARGAR',
  COMISIONES_MODIFICAR = 'COMISIONES_MODIFICAR',
  COMISIONES_DAR_BAJA = 'COMISIONES_DAR_BAJA',
  COMISIONES_LISTAR_PORCENTAJES = 'COMISIONES_LISTAR_PORCENTAJES',
  COMISIONES_CARGAR_PORCENTAJES = 'COMISIONES_CARGAR_PORCENTAJES',
  COMISIONES_BORRAR_PORCENTAJES = 'COMISIONES_BORRAR_PORCENTAJES',
  COMISIONES_LISTAR_CALCULADAS = 'COMISIONES_LISTAR_CALCULADAS',
  COMISIONES_MARCAR_PAGO_CALCULADAS = 'COMISIONES_MARCAR_PAGO_CALCULADAS',
  ACREDITACION_DE_FONDOS_LISTAR = 'ACREDITACION_DE_FONDOS_LISTAR',
  ACREDITACION_DE_FONDOS_CARGAR = 'ACREDITACION_DE_FONDOS_CARGAR',
  ACREDITACION_DE_FONDOS_MODIFICAR = 'ACREDITACION_DE_FONDOS_MODIFICAR',
  ACREDITACION_DE_FONDOS_LISTAR_TOTALES = 'ACREDITACION_DE_FONDOS_LISTAR_TOTALES',
  LICITACIONES_LISTAR_LIMITE_ADJUDICADOS = 'LICITACIONES_LISTAR_LIMITE_ADJUDICADOS',
  TITULO_LICITADO_CARGAR = 'TITULO_LICITADO_CARGAR'
}
